import axios from 'axios';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';
import { DOWNLOAD_BROCHURE } from './types';

export const updateBrochureProduct = (slug='') => async dispatch => {
    dispatch({
        type: DOWNLOAD_BROCHURE,
        payload: slug
    });
}

export const downloadBrochure = (form) => async dispatch => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/support/brochure-download/`, JSON.stringify(form), JSON_CONTENT_FORM_CONFIG);
    return res.data;
}