import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";
import { getAnnouncements } from '../../actions/announcements/announcements';
import { Link } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { getFileTypeFromUrl } from '../../utils/fileType';

const AnnouncementList = ({ update_page_meta }) => {
    update_page_meta({
        title: "Announcements | Mobipay",
        description: "Announcements from mobipay"
    })
    const [Announcements, setAnnouncements] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [fetching, setFetching] = useState(false);
    const [modal, setModal] = useState({
        isOpen: false,
        item: null
    })
    useEffect(() => {
        let modalInstance = Modal.getOrCreateInstance(document.getElementById("announcement_detail_modal"), {backdrop: 'static', keyboard: false});
        if (!modal.isOpen){
            modalInstance.hide();
        }
        else{
            modalInstance.show();
        }
    }, [modal.isOpen]);
    const bottomElementRef = useRef();
    const fetchMore = async () => {
        if (page > totalPages) return;
        try {
          setFetching(true);
          const response = await getAnnouncements(page, perPage);
          if (response.success) {
            setAnnouncements((prevAnnouncements) => {
              if (page === 1) {
                return response.updates.map((announcement) => ({
                    ...announcement,
                    animated: false,
                })); // Set the initial announcements
              } else {
                const animatedAnnouncements = prevAnnouncements.map((announcement) => ({
                    ...announcement,
                    animated: true,
                }));
                const newAnnouncements = response.updates.map((announcement) => ({
                    ...announcement,
                    animated: false,
                }));
                return [...animatedAnnouncements, ...newAnnouncements]; // Append new Announcements
              }
            });
            setTotalPages(response.num_pages);
            setPage(page + 1);
          }
        } catch (error) {
          console.error("Error fetching more announcements:", error);
        } finally {
          setFetching(false);
        }
      };
    const loadMoreContent = () => {
        if (page > totalPages || fetching) return
        fetchMore();
    };
    useEffect(() => {
        const options = {
          root: null, // viewport
          rootMargin: "0px",
          threshold: 0.1, // When 10% of the element is visible, trigger the callback
        };
    
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            loadMoreContent();
          }
        }, options);
    
        if (bottomElementRef.current) {
          observer.observe(bottomElementRef.current);
        }
    
        return () => {
          if (bottomElementRef.current) {
            observer.unobserve(bottomElementRef.current);
          }
        };
    }, [bottomElementRef, page, totalPages, fetching]);
  return (
    <>
        <PageHeader title="Announcements" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "Announcements"
                }
            ]} bgImg={require('../../assets/images/page-bg/about.webp')} />
        <div class="support-area bg-color-fbfbfb pt-100 pb-70">
			<div class="container">
				<div class="row">
                    {
                        Announcements.length > 0 &&
                        Announcements.map((item, index) => {
                            let animation_class = '';
                            if (!item.animated){
                                let quotient = (index+1) % 3;
                                animation_class = 'wow fadeInUp ';
                                animation_class += quotient === 0 ? 'delay-0-6s' : quotient === 1 ? 'delay-0-2s' : 'delay-0-4s'
                            }
                            return (
                                <div class="col-lg-4 col-sm-6">
                                    <div class={`single-support ${animation_class}`}>
                                    {/* <div class={`single-support`}> */}
                                        {
                                            item?.file?.id &&
                                            <img src={item?.thumbnail?.url || item?.file?.url} style={{maxWidth: "200px", maxHeight: "200px"}} alt={item.title} />
                                        }
                                        <h3>
                                            {item.title}
                                        </h3>
                                        <p style={{whiteSpace: "normal", wordBreak: "break-all"}}>{item?.small_description}</p>
                                        <Link to={item.slug} onClick={(e) => {e.preventDefault(); setModal({isOpen: true, item: item})}} class="read-more">View Details</Link>
                                    </div>
                                </div>
                            )
                        })
                    }
				</div>
			</div>
		</div>
        <div ref={bottomElementRef}></div>

        {/* Modal */}
        <div className="modal fade" id="announcement_detail_modal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">{modal.item?.title}</h5>
                    <button type="button" className="close" onClick={e => setModal({isOpen: false, item: null})} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                        {
                            modal.item !== null &&
                            <>
                                <div class={`single-support`}>
                                    {modal.item?.file?.id && (
                                    <>
                                        {getFileTypeFromUrl(modal.item?.file?.url) === 'image' ? (
                                        <img src={modal.item?.file?.url} style={{ maxWidth: "100%" }} alt={modal.item?.title} />
                                        ) : getFileTypeFromUrl(modal.item?.file?.url) === 'video' ? (
                                        <video src={modal.item?.file?.url} style={{ maxWidth: "100%" }} controls autoplay></video>
                                        ) : (
                                        <></>
                                        )}
                                    </>
                                    )}
                                    <h3>
                                        {modal.item?.title}
                                    </h3>
                                    <p style={{whiteSpace: "normal", wordBreak: "break-all"}}>{modal.item?.description}</p>
                                </div>
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={e => setModal({isOpen: false, item: null})}>Okay</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    
  )
}

export default connect(null, { update_page_meta })(AnnouncementList)