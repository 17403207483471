import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import countries from '../data/countries.json';

const PhoneInput = ({name, Form, setForm, className}) => {
    const [Country, setCountry] = useState(countries.find(item => item.iso3 === "IND"));
    const listItems = countries.map(country => {
        return (
            <li key={country.id}><span style={{cursor: "pointer"}} onClick={() => setCountry(country)} className="dropdown-item">{country.emoji} {country.name}({country.phone_code})</span></li>
        )
    })
    const update_field = (e) => {
        setForm({...Form, [name]: Country.phone_code + e.target.value})
    }
    return (
        <>
            <div className={`input-group ${className}`}>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" 
                        type="button" 
                        id="phonecodeselectordropdown" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                        style={{
                            background: "#eeeeee",
                            border: "none",
                            height: "calc(100% - 2px)",
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                            marginTop: "1px",
                            color: "#141414"
                        }}>
                        {Country.emoji} {Country.phone_code}
                    </button>
                    <ul className="dropdown-menu" 
                        aria-labelledby="phonecodeselectordropdown"
                        style={{
                            maxHeight: "300px",
                            overflow: "scroll"
                        }}>
                        {listItems}
                    </ul>
                </div>
                <input type="text" name={name} required={true} data-error="Please enter your number" className="form-control" placeholder="Your Phone No" 
                            value={Form[name]?.substring(Country.phone_code.length)} onChange={update_field}/>
            </div>
            
        </>
    )
}

export default connect(null, {})(PhoneInput);