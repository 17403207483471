export const team = {
    directors: [
        {
            "name": "Taron Mohan",
            "thumbnail": require("../assets/images/team/Taron_Mohan.png"),
            "description": <>Serial entrepreneur <br />
                            Technology geek<br />
                            4th venture<br />
                            Successful exits in the past<br />
                            Precision manufacturing experience adds to total solution delivery capability<br />
                            Passion to build this to a global platform</>,
            "linkedin": "https://www.linkedin.com/in/taron-mohan-07099/"
        },
        {
            "name": "Rajinder Sharma",
            "thumbnail": require("../assets/images/team/Rajinder_Sharma.png"),
            "description": <>Over 40 years as a legal counsel<br />
            Amongst Top 100 legal counsels in india<br />
            Extensive corporate experience<br />
            Varied multi national experience</>,
            "linkedin": "https://www.linkedin.com/in/lexgenius/"
        },
        {
            "name": "Vikram Mehta",
            "thumbnail": require("../assets/images/team/vikram-mehta.png"),
            "description": <>Over 30 years of experience in Business Development, Sales & Distribution, Profit Centre Management and General Management. A financial services professional with experience in Consumer Banking, Credit Cards and Life Insurance.</>,
            "linkedin": "https://www.linkedin.com/in/vikram-mehta-755aa32/"
        }
    ],
    management: [
        {
            "name": "Taron Mohan",
            "thumbnail": require("../assets/images/team/Taron_Mohan.png"),
            "description": <>Taron, an engineering graduate from IIT Delhi, is known for his passion for innovative & futurist technologies which help organizations offer very compelling and user-friendly services to their customers. He is an innovator and has won several industry awards & recognitions for his company in India and abroad.</>,
            "linkedin": "https://www.linkedin.com/in/taron-mohan-07099/"
        },
        {
            "name": "Vikram Mehta",
            "thumbnail": require("../assets/images/team/vikram-mehta.png"),
            "description": <>Over 30 years of experience in Business Development, Sales & Distribution, Profit Centre Management and General Management. A financial services professional with experience in Consumer Banking, Credit Cards and Life Insurance.</>,
            "linkedin": "https://www.linkedin.com/in/vikram-mehta-755aa32/"
        },
        {
            "name": "Ravi Mittal",
            "thumbnail": require("../assets/images/team/Ravi_Mittal.png"),
            "description": <>Ravi has a rich IT career in launching and driving new IT initiatives, re-designing corporate IT infra and contributing towards the achievement of organizational objectives. Ravi also takes care of Technical Support, Operations, Service Delivery, IT Security and Vendor Management.</>,
            "linkedin": "https://www.linkedin.com/in/ravi-mittal-4887939/"
        },
        {
            "name": "Vinay Kumar",
            "thumbnail": require("../assets/images/team/vinay_kumar.png"),
            "description": <>Professional with 20+ years of experience in strategizing, planning, defining, building, and launching commercially viable and scalable software products and solutions. Experience in managing Fintech products and payment systems. Skilled around leading large teams, collaborating with various key stakeholders to drive competitive, stable, and profitable growth for business.</>,
            "linkedin": "https://www.linkedin.com/in/vinay-kumar-99439a31"
        },
        {
            "name": "Girraj Sharma",
            "thumbnail": require("../assets/images/team/girraj.png"),
            "description": <>An accomplished business operations leader with more than 15 years of experience, known for successfully leading and expanding e-commerce and marketplace platforms in Africa and India. Specializes in establishing scalable, profit-generating distribution models for startups. Proficient in vendor onboarding, negotiations, and driving business success. Demonstrated strength in managing large teams and developing them to align with the business's needs.</>,
            "linkedin": "https://www.linkedin.com/in/girrajsharma-iwg/"
        },
        // {
        //     "name": "Abhishek Gupta",
        //     "thumbnail": require("../assets/images/team/ABHISHEK.png"),
        //     "description": <>A seasoned Professional with over 18 years of experience. Proven track record and experience in delivering exceptional results through leading teams in the Fintech and Insurance industry. Skilled around leading large teams, conceptualizing and executing go-to –market strategies to drive competitive, stable, and profitable growth for business.</>,
        //     "linkedin": "https://www.linkedin.com/in/abhishek-gupta-a18005223/"
        // }
    ]
}