import { configureStore } from '@reduxjs/toolkit';
// import { authReducer } from './reducers/accounts/auth';
// import { userReducer } from './reducers/accounts/user';
import { pagemetaReducer } from './reducers/pagemetaReducer';
import { brochureReducer } from './reducers/brochureReducer';

const reducer = {
    // page
    page: pagemetaReducer,

    brochure: brochureReducer,
  }
  
  const store = configureStore({
    reducer: reducer
  })
  
  export default store;