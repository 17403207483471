import React from "react";
import PageHeader from "../components/PageHeader";
import { update_page_meta } from "../actions/page/header";
import { connect } from "react-redux";

const Disclaimer = ({ update_page_meta }) => {
    update_page_meta({
        title: "Disclaimer | Mobipay",
        description: "Mobipay disclaimer"
    })
    return (
        <>
            <PageHeader title="Disclaimer" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "Disclaimer"
                }
            ]} />
            <section className="ptb-50">
                <div className="container">
                    <div className="main-content-text">
                        <p>
                            Mobipay Securiservices Pvt. Ltd. provides the www.mobipaysecuri.com web site as a service to public and web site owners.
                        </p>

                        <p>
                            Mobipay Securiservices Pvt. Ltd. is not responsible for, and expressly disclaims all liability for, damages of any kind arising out of use, reference to, or reliance on any information contained within the site. While the information contained within the site is periodically updated, no guarantee is given that the information provided in this web site is correct, complete, and up-to-date.
                        </p>

                        <p>
                            Although the Mobipay Securiservices Pvt. Ltd. web site may include links providing direct access to other Internet resources, including web sites, Mobipay Securiservices Pvt. Ltd. is not responsible for the accuracy or content of information contained in these sites.
                        </p>

                        <p>
                            Links from Mobipay Securiservices Pvt. Ltd. to third-party sites do not constitute an endorsement by Mobipay Securiservices Pvt. Ltd. of the parties or their products and services. The appearance on the web site of advertisements and product or service information does not constitute an endorsement by Mobipay Securiservices Pvt. Ltd., and Mobipay Securiservices Pvt. Ltd. has not investigated the claims made by any advertiser. Product information is based solely on material received from suppliers.
                        </p>

                        <p>
                            Logos, Pictures and Trademarks of any third party used on this website are only for information purposes. Mobipay Securiservices Pvt. Ltd. has no claim to represent them as its own proprietary or in association with unless otherwise specified.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, {update_page_meta})(Disclaimer);