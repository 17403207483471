import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

const Banner = ({}) => {
    return (
        <div className="banner-area bg-3 ptb-100 bg-color-f5f5f5">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="banner-content style-three">
						<div className="row align-items-center">
							<div className="col-lg-6">
								{/* <h1 className="wow fadeInUp delay-0-2s">21st Century's Payment Technologies</h1> */}
                                <h1 className="wow fadeInUp delay-0-2s">
                                    Building for Bharat... <br />And the World
                                </h1>
								<p className="wow fadeInUp delay-0-6s">
                                    We pride ourselves in delivering disruptive, innovative and payment solutions designed for the future. 
                                    Incorporating multiple service verticals into a single integrated offering, coupled with the business 
                                    applications, these would help you re-define your customer service offerings.
                                </p>

								<div className="banner-btn wow fadeInUp delay-0-8s">
									<Link to="/about/story/" className="default-btn">
                                        The Story Behind Mulah
                                        {/* <span style={{display: "inline"}}>
                                            <img src={require("../../assets/images/mulah-logo-white.png")} 
                                                alt="Mulah Logo"
                                                style={{height: "26px"}} /> 
                                            &nbsp;
                                            The Story Behind Mulah
                                        </span>	 */}
									</Link>
									<Link to="/about/building-for-bharat/" className="default-btn active">
										Read More
									</Link>
								</div>

                                <Swiper
                                    style={{alignItems: "center"}}
                                    loop={true}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 500,
                                        disableOnInteraction: false,
                                      }}
                                    spaceBetween={50}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2
                                        },
                                        414: {
                                            slidesPerView: 2
                                        },
                                        576: {
                                            slidesPerView: 3
                                        },
                                        768: {
                                            slidesPerView: 4
                                        },
                                        992: {
                                            slidesPerView: 5
                                        },
                                        1200: {
                                            slidesPerView: 5
                                        }
                                    }}
                                    className="partner-slide"
                                    >
                                    <SwiperSlide>
                                        <div className="partner-item">
                                            <img src={require("../../assets/images/partners/visa.png")} alt="Visa" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/mastercard.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/pcidss.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/emvco.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/jcb.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/rupay.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/unionpay.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="partner-item">
										<img src={require("../../assets/images/partners/american-express.png")} alt="Image" />
									</div>
                                    </SwiperSlide>
                                </Swiper>
							</div>

							<div className="col-lg-6">
								<div className="banner-img three wow fadeInUp delay-0-2s">
									<img src={require("../../assets/images/banner/built_for_rural_india.webp")} alt="Image" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Banner;