import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";

const Story = ({ update_page_meta }) => {
    update_page_meta({
        title: "The story behind the Company | Mobipay",
        description: "The story behind the Company"
    })
    return (
        <>
            <PageHeader title="The story behind the Company" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "About",
                    loc: "/about/"
                },
                {
                    title: "The story behind the Company"
                }
            ]} bgImg={require('../../assets/images/page-bg/story-behind-the-company.webp')} />
            <div className="unified-platform-area bg-color-fff9f8 pt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInLeft delay-0-4s">
                                {/* <div className="mb-30"> */}
                                <div className="">
                                    <span className="top-title">Mulah</span>
                                    <h2>Mulah: the story behind it……</h2>
                                    <p>
                                        <strong>Mulah: </strong>Money/Moolah,… is a global word and it all translates to a basic need today ….
                                        <strong>Currency in your pocket.</strong> This is what gives everyone the confidence to live, grow
                                        and walk ahead in life.
                                    </p>
                                    <p>
                                        <strong>Mobipay</strong> - with a focus to upgrade the unbanked and the under-banked strata to
                                        the formal economy of digital payments, today embarks on this venture in partnership with NPCI,
                                        IDFC Bank, the Government of India, the micro finance industry and help take the national GDP
                                        to USD 5 trillion by 2025
                                    </p>

                                    <p>
                                        <strong>Mobipay</strong> owes its existence to the frequent trips of the founder – Taron Mohan,
                                        who in his telco support service operations in Africa in 2007, 2008, saw and experienced the
                                        explosion of mobile money. Beginning with M-Pesa launched by Safaricom in Kenya and then expanding
                                        like wildfire across Africa and then the world, mobile banking, payments and wallets are the core
                                        for growth in economies globally.
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-img  wow fadeInRight delay-0-4s ml-50">
                                <img src={require("../../assets/images/mulah-creative-1.webp")} alt="Mulah" />
                                {/* <img src={require("../../assets/images/unified-shape-1.png")} className="unified-shape-1" alt="Image" /> */}
                                {/* <img src={require("../../assets/images/unified-shape-2.png")} className="unified-shape-2" alt="Image" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="bg-color-fff9f8 pb-50">
                <div className="container">
                    <div className="main-content-text">
                        <p>
                            BUT, there was an inherent gap in the technologies there :
                        </p>
                        <ul style={{ listStyleType: "disc" }}>
                            <li style={{ padding: 0, marginLeft: "35px" }}>
                                <strong>They were unsecured</strong> (a mobile device has no security at all)
                            </li>
                            <li style={{ padding: 0, marginLeft: "35px" }}>
                                The base “data transport layer” was <strong>USSD or in some cases SMS ( again unsecured)</strong>
                            </li>
                            <li style={{ padding: 0, marginLeft: "35px" }}>
                                And it offered a <strong>limited service portfolio.</strong>
                            </li>
                        </ul>
                        <p>
                            So Taron got down to working on a future proof, secure technology platform to power these unbanked & under-banked users with a secure, simple and an all-encompassing platform to help them graduate to the formal “digital economy” and move to the path of growth and upgrade their livelihood.
                        </p>
                        <p>
                            <strong>
                                Thus was born Mulah…. The unique, patented financial inclusion platform for the unbanked & the under-banked, to move into the world of digital payments, be included in the formal financial inclusion economy and progress ahead.
                            </strong>
                        </p>
                        <p>
                            The tag lines to the logo : <strong>Mobility, Money and Security</strong> are the inherent base strengths in the solution and the guide to the future roadmap of the company.
                        </p>

                        <p>
                            <Link class="read-more" to="/about/building-for-bharat/">
                                <strong style={{ color: "#ee6a3e" }}>
                                    Building for Bharat and the world… that’s where we are headed and getting the unbanked/under-banked to upgrade to a world of digital payments
                                </strong>
                            </Link>
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(Story);