import React from "react";
import { connect } from "react-redux";

const SuperLead72ISM = ({}) => {
    return (
        <>
            SuperLead72ISM
        </>
    )
}

export default connect(null, {})(SuperLead72ISM);