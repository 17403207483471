import React from "react";
import { connect } from "react-redux";

const CardTokenisation = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/Card_Tokenisation_and_M-Commerce_Payments_Including_TapNPay.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our in-device card tokenization solution for m-commerce payments, a cutting-edge 
                                        technology that provides a secure and convenient way for consumers to make mobile transactions. 
                                        With card tokenizing directly on a mobile device, we can integrate in-app M-commerce payments and 
                                        TapNPay services for an EMV compliant “card present” transaction, which is safer, simpler, faster 
                                        and at lower costs over exciting Online Payment Gateway services.
                                    </p>
                                    <p>
                                        We support multiple payment methods - debit/credit /pre-paid cards, wallets and UPI and also 
                                        is fully compliant with industry standards and regulations, providing consumers and merchants 
                                        peace of mind when making mobile transactions.
                                    </p>
                                    
                                    <p>
                                        Choose our in-device card tokenization solution for m-commerce payments and experience a 
                                        new level of security and convenience for all your mobile transactions.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(CardTokenisation);