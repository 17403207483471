import React from "react";
import { connect } from "react-redux";

const EventTicketing = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/products/applications/Theme_Park_Event_Ticketing.webp")} alt="Event ticketing application" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our state-of-the-art automated park ticketing solution! Our system utilizes 
                                        contactless cards and QR code tickets for quick and easy entry into the park. Say goodbye to 
                                        long lines and paper tickets, as our solution allows for seamless entry with just a tap of 
                                        your contactless card or scan of your QR code ticket. Plus, it's safe and contactless, which 
                                        is ideal in today's environment. Experience the future of park ticketing with our solution.
                                    </p>
                                    <p>
                                        Configurable for :
                                    </p>
                                    <ul>
                                        <li>Multiple venues / events, each with their own settings for pricing, open and close timings, visitor capacity per hour if any and more</li>
                                        <li>Visitor limitations in a venue if any</li>
                                        <li>Visitor types / individual venue pricing per visitor type</li>
                                        <li>Bundled packages and pricing</li>
                                        <li>Special event based pricing / packages</li>
                                    </ul>
                                    <p>
                                        Inclusions :
                                    </p>
                                    <ul>
                                        <li>Parking and bus rides</li>
                                        <li>Retailer payments / billing and terminals</li>
                                    </ul>
                                    <p>Ticketing options on :</p>
                                    <ul>
                                        <li>Online, offline, mobile app</li>
                                        <li>Single ticket for family or individual tickets</li>
                                        <li>Pre-paid card for insta entry into a venue</li>
                                    </ul>
                                    <p>
                                        And more… Come see our solution in operation at India’s largest park – The Statue of Unity park 
                                        in Gujarat. Draws over 6 mill visitors / year.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(EventTicketing);