import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";

const Bharat = ({ update_page_meta }) => {
    update_page_meta({
        title: "Building for Bharat | Mobipay",
        description: "Building for Bharat"
    })
    return (
        <>
            <PageHeader title="Building for Bharat" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "About",
                    loc: "/about/"
                },
                {
                    title: "Building for Bharat"
                }
            ]} bgImg={require('../../assets/images/page-bg/building-for-bharat.webp')} />
            <section className="bg-color-fff9f8 ptb-50">
                <div className="container">
                    <div className="main-content-text">
                        <h3>
                            Building for Bharat
                        </h3>
                        <p>
                            <strong>Bharat, the true India, </strong> resides in the rural villages, tier 2 and 3 cities and contributes 
                            to over 65% of the national GDP. Be it in agriculture, handicrafts or the MSME sector, the country cannot 
                            forget their contribution to the nation. While mainstream India has been at the forefront of the digital 
                            invasion, this <strong>“true India”</strong> has been lagging behind, primarily due to 
                            <strong>local limitations</strong> on:
                        </p>
                        <ul style={{listStyleType: "disc"}}>
                            <li style={{padding: 0, marginLeft: "35px"}}>
                                <strong>Exposure to the digital platforms</strong> for payments, services and transactional points
                            </li>
                            <li style={{padding: 0, marginLeft: "35px"}}>
                                <strong>Education and hand holding</strong> for “service on-boarding”
                            </li>
                            <li style={{padding: 0, marginLeft: "35px"}}>
                                <strong>Availability of the infrastructure</strong> to help them “on-board” the digital India rails.
                            </li>
                        </ul>
                        <p>They still are limited by the above and remain in a “cash” economy.</p>
                        {/* <div className="row">
                            <div className="col-md-6"> */}
                                <p>
                                    At Mobipay, our primary focus has been on the uplifting of this “real Bharat” to “digital India” by helping 
                                    them upgrade on their basic mobile handset for a suite of digital payment services on 
                                    <strong> their basic mobile phone:</strong>
                                </p>
                                <ul style={{listStyleType: "disc"}}>
                                    <li style={{padding: 0, marginLeft: "35px"}}>
                                        <strong>Access to their bank account</strong> using the UPI rails
                                    </li>
                                    <li style={{padding: 0, marginLeft: "35px"}}>
                                        <strong>Enabling bill payments, money transfers and merchant payments</strong>
                                    </li>
                                    <li style={{padding: 0, marginLeft: "35px"}}>
                                        <strong>EMI payments and UPI autopay</strong>
                                    </li>
                                    <li style={{padding: 0, marginLeft: "35px"}}>
                                        <strong>Requests for micro loans and insurances from the device</strong>
                                    </li>
                                    <li style={{padding: 0, marginLeft: "35px"}}>
                                        And then extending to hosting:
                                        <ul style={{listStyleType: "circle"}}>
                                            <li style={{padding: 0, marginLeft: "35px"}}>
                                                The <strong>national ID / health certificates</strong> on the same device
                                            </li>
                                            <li style={{padding: 0, marginLeft: "35px"}}>
                                                <strong>Offline UPI lite</strong> on the mobile
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>And enabling for them the suite of services to board the “digital India” rails and contribute to the national GDP growth in addition to their own growth.</p>
                            {/* </div>
                            <div className="col-md-6"> */}
                                <img style={{maxWidth: "450px", marginTop: "10px", marginBottom: "15px"}} src={require("../../assets/images/Building_for_Bharat.webp")} alt="Building for Bharat" />
                            {/* </div>
                        </div> */}
                        
                        <h6>
                            Why will this segment continue to use the feature phone 
                        </h6>
                        <ul style={{listStyleType: "decimal"}}>
                            <li style={{padding: 0, marginLeft: "35px"}}>
                                <strong>Battery life:</strong> a single charge can last for 2-3 days
                            </li>
                            <li style={{padding: 0, marginLeft: "35px"}}>
                                <strong>Simplicity:</strong> no cache clearing, no re-booting, it just works
                            </li>
                            <li style={{padding: 0, marginLeft: "35px"}}>
                                <strong>Affordability:</strong> INR 1000-1400 Vs INR 8,000+ for a smart phone
                            </li>
                        </ul>
                        <p>
                            And we will continue to sell over 10-12 mill phones / month in this segment
                        </p>
                        <div className="text-center" style={{maxWidth: "350px"}}>
                            <img src={require("../../assets/images/mulah-creative-2.webp")} alt="Mulah" />
                        </div>
                        <div className="text-center">
                            <Link class="read-more" to="/shop/secure-banking-on-feature-phones/">...the Solution</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(Bharat);