import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({title, breadcrumbs, bgImg}) => {
    const breadcrumblistitems = breadcrumbs?.map((page, index) => {
        if (index + 1 === breadcrumbs?.length)
            return (<li className="active">{page.title}</li>);
        return (
            <li>
                <Link to={page.loc}>
                    {page.title} 
                </Link>
            </li>
        )
    });
    return (
        <div className="page-title-area bg-2" style={{backgroundImage: `url(${bgImg ? bgImg : require('../assets/images/page-bg/page-bg-1.jpg')})`}}>
			<div className="container">
				<div className="page-title-content">
					<h1>{title}</h1>
					<ul>{breadcrumblistitems}</ul>
				</div>
			</div>
		</div>
    )
}

export default PageHeader;