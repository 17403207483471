import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";
import { team } from "../../data/team";

const Team = ({ update_page_meta }) => {
    update_page_meta({
        title: "Our Team | Mobipay",
        description: "About mobipay team"
    })
    const directors = team.directors.map((member, index) => {
        return (
            <div className="col-lg-3 col-sm-6" key={index}>
                <div className="single-support wow fadeInUp delay-0-2s" style={{border: "1px solid #ddd", padding: "20px", position: "relative"}}>
                    <img width="250" height="250" src={member.thumbnail} alt={member.name} />
                    <h3>
                        {member.name}
                    </h3>
                    <a href={member.linkedin} target="_blank" className="read-more-icon" style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "5px 10px",
                        borderBottom: "1px solid #ddd",
                        borderLeft: "1px solid #ddd"
                    }}><i class="flaticon-linkedin" style={{fontSize: "18px"}}></i></a>
                    <p>{member.description}</p>
                </div>
            </div>
        )
    })
    const management = team.management.map((member, index) => {
        return (
            <div className="col-lg-3 col-sm-6" key={index}>
                <div className="single-support wow fadeInUp delay-0-2s" style={{border: "1px solid #ddd", padding: "20px", position: "relative"}}>
                    <img width="250" height="250" src={member.thumbnail} alt={member.name} />
                    <h3>
                        <Link to="">{member.name}</Link>
                    </h3>
                    <a href={member.linkedin} target="_blank" className="read-more-icon" style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "5px 10px",
                        borderBottom: "1px solid #ddd",
                        borderLeft: "1px solid #ddd"
                    }}><i class="flaticon-linkedin" style={{fontSize: "18px"}}></i></a>
                    <p>{member.description}</p>
                </div>
            </div>
        )
    })
    return (
        <>
            <PageHeader title="Our Team" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "About Us",
                    loc: "/about/"
                },
                {
                    title: "Our Team"
                }
            ]} bgImg={require('../../assets/images/page-bg/Our_Team_Banner.webp')} />
            <div className="support-area bg-color-fbfbfb pt-100">
                <div className="container">
                    <div className="section-title">
                        {/* <span className="top-title">Key Features</span> */}
                        <h2>Directors</h2>
                        {/* <p>Connect your money to your friends & family from anywhere, anytime regardless any delay. Lorem ipsum Nullana integer sagittis, eleifend. met, aliquere.</p> */}
                    </div>
                    <div className="row">
                        {directors}
                    </div>
                </div>
            </div>

            <div className="support-area bg-color-fbfbfb pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        {/* <span className="top-title">Key Features</span> */}
                        <h2>Management Team</h2>
                        {/* <p>Connect your money to your friends & family from anywhere, anytime regardless any delay. Lorem ipsum Nullana integer sagittis, eleifend. met, aliquere.</p> */}
                    </div>
                    <div className="row">
                        {management}
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, { update_page_meta })(Team);