import React from "react";
import { connect } from "react-redux";

const NexgoUN20 = ({}) => {
    return (
        <>
            NexgoUN20
        </>
    )
}

export default connect(null, {})(NexgoUN20);