import React from "react";
import { connect } from "react-redux";

const TransitFareCollection = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/products/products/Transit_Fare_Collection_Solutions.webp")} alt="Transit fare collection" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our automatic transit fare collection solution, a cutting-edge technology that 
                                        streamlines the process of collecting fares for public transit. With an integrated NFC and QR 
                                        ticketing solution, allowing passengers to simply tap their contactless payment card or mobile 
                                        device to pay for their fare without the need for cash or tickets.
                                    </p>
                                    <p>
                                        Our Automatic transit fare collection solution is also designed to be cost-effective and easy to 
                                        implement for transit operators, helping to modernize their fare collection systems and improve 
                                        the overall passenger experience.
                                    </p>
                                    
                                    <p>
                                        Choose our Automatic transit fare collection solution and experience the convenience and efficiency 
                                        of contactless fare payment for public transit.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(TransitFareCollection);