import { useSelector } from 'react-redux';
import { DOWNLOAD_BROCHURE } from '../actions/support/types';

const initialState = {
    product_slug: ""
};

export const brochureReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case DOWNLOAD_BROCHURE:
            return {
                ...state,
                product_slug: payload
            };
        default:
            return {
                ...state,
                product_slug: ""
            };
    }
}

export function useBrochureProduct() {
    return useSelector((state) => {
        return state.brochure.product_slug;
    })
}