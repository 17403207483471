import React from "react";
import { connect } from "react-redux";

const QRScanners = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/QR_Scanners.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our state-of-the-art QR scanners, designed to make your transit and retail 
                                        experiences in India more seamless and efficient. With our scanners, you can easily scan 
                                        QR codes for mobile payments, ticketing, and more. These meet the latest C-DAC specifications 
                                        on transit QR codes and come equipped with toughened glasses to meet the harsh operating 
                                        environment in transit services, and are equipped with the latest technology, ensuring 
                                        fast and secure transactions.
                                    </p>
                                    <p>
                                        Upgrade your transit and retail experiences today with our advanced QR scanners.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(QRScanners);