import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {stack as Menu} from 'react-burger-menu';
import { Categories } from "../data/categories";

const Navbar = ({}) => {
    const [scrollY, setScrollY] = useState(window.pageYOffset);
    const [isSticky, setIsSticky] = useState(false);
    function setYoffset() {
        setScrollY(window.pageYOffset);
    }
    useEffect(() => {
        function watchScroll() {
          window.addEventListener("scroll", setYoffset);
        }
        watchScroll();
        return () => {
          window.removeEventListener("scroll", setYoffset);
        };
    });
    useEffect(() => {
        if (scrollY > 150)
            setIsSticky(true)
        else
            setIsSticky(false)
    }, [scrollY]);

    const [Dropdown, setDropdown] = useState({
        about: false
    })

    // Rendered on mobile
    const categories = Categories?.map(item => {
        let dropdownMenu = item?.sub_categories?.map(cat => {
            return (
                <Link to={`/category/${cat.slug}`} className="nav-link">{cat.name}</Link>
            )
        })
        if (item.sub_categories.length > 0)
            return (
                <>
                    <div className="bm-item has-dropdown">
                        <Link  to={`/category/${item.slug}/`} className="nav-link dropdown-toggle">
                            {item.name}
                        </Link>
                        <span style={{cursor: "pointer"}} onClick={e => setDropdown({...Dropdown, [item.slug]: !Dropdown[item.slug]})} className="toggle">
                            {Dropdown[item.slug] ? '-' : '+'}
                        </span>
                    </div>
                    {
                        Dropdown[item.slug] &&
                        <div className="bm-item dropdown-menu">
                            {dropdownMenu}
                        </div>
                    }
                </>
            )
        return (
            <Link to={`/category/${item.slug}/`} className="nav-link">{item.name}</Link>
        )
    })

    const categories_desktop = Categories?.map(item => {
        let dropdownMenu = item?.sub_categories?.map(cat => {
            return (
                <li className="nav-item">
                    <Link to={`/category/${cat.slug}`} className="nav-link">{cat.name}</Link>
                </li>
            )
        })
        if (item.sub_categories.length > 0)
            return (
                <>
                    <li className="nav-item">
                        <Link to={`/category/${item.slug}/`} className="nav-link dropdown-toggle">
                        {item.name}
                        </Link>

                        <ul className="dropdown-menu">

                            {dropdownMenu}

                        </ul>
                    </li>
                </>
            )
        return (
            <li className="nav-item">
                <Link to={`/category/${item.slug}/`} className="nav-link">{item.name}</Link>
            </li>
        )
    })

    const [IsOpen, setIsOpen] = useState(false)

    useEffect(() => {
        // Define the mutation observer callback function
        const handleMutation = (mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'aria-hidden') {
              const ariaHiddenValue = mutation.target.getAttribute('aria-hidden');
              if (ariaHiddenValue === 'true') {
                document.body.classList.remove('menu-open');
              } else {
                document.body.classList.add('menu-open');
              }
            }
          }
        };
    
        // Create a mutation observer targeting the element with the aria-hidden attribute
        const targetNode = document.querySelector('.bm-menu-wrap');
        const observer = new MutationObserver(handleMutation);
    
        // Start observing changes in the 'aria-hidden' attribute
        observer.observe(targetNode, { attributes: true });
    
        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
      }, []);
    
    return (
        <>
            <div className="navbar-fixed fixed-top">
                <div className={`navbar-area style-three ${isSticky ? 'is-sticky' : ''}`}>
                    <div className="mobile-responsive-nav">
                        <div className="container">
                            <div className="mobile-responsive-menu">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={require('../assets/images/logo.png')} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="container text-end">
                            <div className="navbar-nav ms-auto">
                                <Menu right 
                                    // isOpen={IsOpen} 
                                    // onOpen={ handleOnOpen } 
                                    // onClose={ handleOnClose }
                                    >
                                    <div className="has-dropdown">
                                        <Link  to="/about/" className="nav-link dropdown-toggle" style={{float: "left", fontWeight: 600}}>
                                                About
                                        </Link>
                                        <span style={{cursor: "pointer"}} onClick={e => setDropdown({...Dropdown, about: !Dropdown.about})} className="toggle">
                                            {Dropdown.about ? '-' : '+'}
                                        </span>
                                    </div>
                                    {
                                        Dropdown.about &&
                                        <div className="dropdown-menu">
                                            <Link to="/about/" className="nav-link">About Us</Link>
                                            <Link to="/about/story/" className="nav-link">The story behind the Company</Link>
                                            <Link to="/about/building-for-bharat/" className="nav-link">Building for Bharat</Link>
                                            <Link to="/about/team/" className="nav-link">Our Team</Link>
                                            <Link to="/about/our-partners/" className="nav-link">Our Partners</Link>
                                            {/* <Link to="/compliance/" className="nav-link">Statutory Compliance</Link> */}
                                        </div>
                                    }

                                    {categories}
                                    <Link to="/announcements/" className="nav-link">Announcements</Link>
                                    <Link to="/contact-us/" className="nav-link">Contact Us</Link>
                                    {/* <li className="nav-item"  style={{float: "left", fontWeight: 600}}>
                                        <Link to="/contact-us/" className="nav-link">Contact Us</Link>
                                    </li> */}
                                </Menu>
                            </div>
                        </div>
                    </div>

                    
        
                    <div className="desktop-nav style-three">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <Link className="navbar-brand" to="/">
                                    <img src={require('../assets/images/white-logo.png')} alt="logo" />
                                </Link>
        
                                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto">
        
                                        <li className="nav-item">
                                            <Link to="/about/" className="nav-link dropdown-toggle">
                                                About
                                            </Link>
        
                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/about/" className="nav-link">About Us</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/about/story/" className="nav-link">The story behind the Company</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/about/building-for-bharat/" className="nav-link">Building for Bharat</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/about/team/" className="nav-link">Our Team</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/about/our-partners/" className="nav-link">Our Partners</Link>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <Link to="/compliance/" className="nav-link">Statutory Compliance</Link>
                                                </li> */}

                                            </ul>
                                        </li>
        
                                        {categories_desktop}

                                        
                                        <li className="nav-item">
                                            <Link to="/announcements/" className="nav-link">Announcements</Link>
                                        </li>
        
                                        <li className="nav-item">
                                            <Link to="/contact-us/" className="nav-link">Contact Us</Link>
                                        </li>
                                    </ul>
        
                                    {/* <div className="others-options">
                                        <ul>
                                            <li>
                                                <a href="login.html" className="default-btn login">
                                                    Login
                                                </a>
                                            </li>
                                            <li>
                                                <a href="register.html" className="default-btn">
                                                    Register
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </nav>
                        </div>
                    </div>
        
                    {/* <div className="others-option-for-responsive">
                        <div className="container">
                            <div className="dot-menu">
                                <div className="inner">
                                    <div className="circle circle-one"></div>
                                    <div className="circle circle-two"></div>
                                    <div className="circle circle-three"></div>
                                </div>
                            </div>
                            
                            <div className="container">
                                <div className="option-inner">
                                    <div className="others-options justify-content-center d-flex align-items-center">
                                        <ul>
                                            <li>
                                                <a href="login.html" className="default-btn login">
                                                    Login
                                                </a>
                                            </li>
                                            <li>
                                                <a href="register.html" className="default-btn">
                                                    Register
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(Navbar);