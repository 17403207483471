export const compliance = {
    agmNotices: [
        {
            title: "AGM 2017-18",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/agm/2017-18.pdf`
        },
        {
            title: "AGM 2018-19",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/agm/2018-19.pdf`
        },
        {
            title: "AGM 2019-20",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/agm/2019-20.pdf`
        },
        {
            title: "AGM 2020-21",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/agm/2020-21.pdf`
        },
        {
            title: "AGM 2021-22",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/agm/2021-22.pdf`
        },
    ],
    eogm: [
        {
            title: "2017",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/eogm/2017.pdf`
        },
        {
            title: "2018",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/eogm/2018.pdf`
        },
        {
            title: "2019",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/eogm/2019.pdf`
        },
        {
            title: "2022",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/eogm/2022.pdf`
        },
        {
            title: "2023",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/eogm/2023.pdf`
        },
    ],
    annualReturns: [
        {
            title: "FY 2017-18",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/annualreturns/2017-18.pdf`
        },
        {
            title: "FY 2018-19",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/annualreturns/2018-19.pdf`
        },
        {
            title: "FY 2019-20",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/annualreturns/2019-20.pdf`
        },
        {
            title: "FY 2020-21",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/annualreturns/2020-21.pdf`
        },
        {
            title: "FY 2021-22",
            description: "",
            url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/compliance/annualreturns/2021-22.pdf`
        },
    ]
}