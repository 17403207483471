import React from "react";

const ContentSection2 = ({}) => {
    return (
        <div className="unified-platform-area ptb-50">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
							<img src={require("../../assets/images/unified-img-2.png")} alt="Image" />
						</div>
					</div>

					<div className="col-lg-6">
						<div className="unified-platform-content wow fadeInRight delay-0-4s">
							<div className="mb-30">
								<span className="top-title">Unified Platform</span>
								<h2>A Fully Integrated Suite for Any Payment Scenario</h2>
								<p>Bringing disruptive creativity and technology innovation to customers, bridging gaps in existing services and targeted to the masses to reach the last mile. Including both legs of transactions – customer and merchant / agent, we help you bridge the digital gap on financial inclusion/digital payments/transit and more.</p>
							</div>

							{/* <div className="row">
								<div className="col-lg-6 col-sm-6">
									<div className="single-unified-platform">
										<i className="flaticon-wallet"></i>
										<h3>Split The Necessities</h3>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
									</div>
								</div>
								<div className="col-lg-6 col-sm-6">
									<div className="single-unified-platform">
										<i className="flaticon-id-card"></i>
										<h3>Pay For Business</h3>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default ContentSection2;