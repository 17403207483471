import React from "react";
import { connect } from "react-redux";
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";
import { partners } from "../../data/partners";

const SingleItem = ({item, delayClass}) => {
    return (
        <div class="col-lg-3 col-sm-6">
            <div class={`single-support wow fadeInUp ${delayClass}`} style={{border: "1px solid #ddd"}}>
                <img src={item.thumbnail} alt={item.name} style={{transform: "scale(0.75)"}} title={item.name} />
                {/* <h3>
                    <a href="faq.html">Send Money</a>
                </h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend consectetur adipiscing.</p>
                <a href="features.html" class="read-more">View Details</a> */}
            </div>
        </div>
    )
}

const Partners = ({update_page_meta}) => {
    update_page_meta({
        title: "Our Partners | Mobipay",
        description: "Our partners"
    })
    const getDelayClass = (index) => {
        switch(index % 4){
            case 0:
                return 'delay-0-2s';
            case 1:
                return 'delay-0-4s';
            case 2:
                return 'delay-0-6s';
            case 3:
                return 'delay-0-8s';
        }
    }
    const technological = partners.technological.map((item, index) => <SingleItem key={index} item={item} delayClass={getDelayClass(index)} />);
    const customers = partners.customers.map((item, index) => <SingleItem key={index} item={item} delayClass={getDelayClass(index)} />);
    const strategic = partners.strategic.map((item, index) => <SingleItem key={index} item={item} delayClass={getDelayClass(index)} />);
    return (
        <>
            <PageHeader title="Our Partners" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "About Us",
                    loc: "/about/"
                },
                {
                    title: "Our Partners"
                }
            ]} bgImg={require("../../assets/images/page-bg/Our-Partners.webp")} />
            <div class="support-area bg-color-fbfbfb pt-50">
                <div class="container">
                    <div class="section-title">
                        {/* <span class="top-title">Key Features</span> */}
                        <h2>Technology Support</h2>
                        {/* <p>Connect your money to your friends & family from anywhere, anytime regardless any delay. Lorem ipsum Nullana integer sagittis, eleifend. met, aliquere.</p> */}
                    </div>
                    <div class="row">
                        {technological}
                    </div>
                </div>
		    </div>
            <div class="support-area bg-color-fbfbfb pt-50">
                <div class="container">
                    <div class="section-title">
                        {/* <span class="top-title">Key Features</span> */}
                        <h2>Strategic Partners</h2>
                        {/* <p>Connect your money to your friends & family from anywhere, anytime regardless any delay. Lorem ipsum Nullana integer sagittis, eleifend. met, aliquere.</p> */}
                    </div>
                    <div class="row">
                        {strategic}
                    </div>
                </div>
		    </div>
            <div class="support-area bg-color-fbfbfb ptb-50">
                <div class="container">
                    <div class="section-title">
                        {/* <span class="top-title">Key Features</span> */}
                        <h2>Customers</h2>
                        {/* <p>Connect your money to your friends & family from anywhere, anytime regardless any delay. Lorem ipsum Nullana integer sagittis, eleifend. met, aliquere.</p> */}
                    </div>
                    <div class="row">
                        {customers}
                    </div>
                </div>
		    </div>
        </>
    )
}

export default connect(null, {update_page_meta})(Partners);