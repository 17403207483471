import React from "react";
import { connect } from "react-redux";
import PageHeader from "../components/PageHeader";
import { update_page_meta } from "../actions/page/header";
import { compliance } from "../data/compliance";

const Compliance = ({ update_page_meta }) => {
    update_page_meta({
        title: "Compliance | Mobipay",
        description: "Corporate disclosure files"
    })
    const agmNotices = compliance.agmNotices.map((item, index) => {
        return (
            <div className="col-lg-3 col-sm-6" key={index}>
                <div className="single-country wow fadeInLeft delay-0-2s">
                    <h3>
                        {item.title}
                    </h3>
                    {/* <p>{item.description}</p> */}
                    <a href={item?.url} target="_blank" className="read-more">
                        View
                        <i className="ri-arrow-right-s-line"></i>
                    </a>
                </div>
            </div>
        )
    })
    const eogm = compliance.eogm.map((item, index) => {
        return (
            <div className="col-lg-3 col-sm-6" key={index}>
                <div className="single-country wow fadeInLeft delay-0-2s">
                    <h3>
                        {item.title}
                    </h3>
                    {/* <p>{item.description}</p> */}
                    <a href={item?.url} target="_blank" className="read-more">
                        View
                        <i className="ri-arrow-right-s-line"></i>
                    </a>
                </div>
            </div>
        )
    })
    const annualReturns = compliance.annualReturns.map((item, index) => {
        return (
            <div className="col-lg-3 col-sm-6" key={index}>
                <div className="single-country wow fadeInLeft delay-0-2s">
                    <h3>
                        {item.title}
                    </h3>
                    {/* <p>{item.description}</p> */}
                    <a href={item?.url} target="_blank" className="read-more">
                        View
                        <i className="ri-arrow-right-s-line"></i>
                    </a>
                </div>
            </div>
        )
    });
    return (
        <>
            <PageHeader title="Compliance" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "Compliance"
                }
            ]} bgImg={require("../assets/images/page-bg/Compliance_Banner.webp")} />
            <section className="pt-100">
                <div className="container">
                    <div className="section-title">
                        <h2>Notice to Annual General Meetings</h2>
                    </div>
                    <div className="row justify-content-center">
                        {agmNotices}
                    </div>
                </div>
            </section>

            <section className="pt-100">
                <div className="container">
                    <div className="section-title">
                        <h2>Extra Ordinary General Body Meetings</h2>
                    </div>
                    <div className="row justify-content-center">
                        {eogm}
                    </div>
                </div>
            </section>

            <section className="ptb-50">
                <div className="container">
                    <div className="section-title">
                        <h2>Annual Return</h2>
                    </div>
                    <div className="row justify-content-center">
                        {annualReturns}
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(Compliance);