import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "bootstrap";
import { useBrochureProduct } from "../reducers/brochureReducer";
import { useNavigate } from "react-router-dom";
import { downloadBrochure, updateBrochureProduct } from "../actions/support/brochure";
import PhoneInput from "../components/PhoneInput";

const BrochureDownloadModal = ({ updateBrochureProduct, downloadBrochure }) => {
    const product_slug = useBrochureProduct();
    useEffect(() => {
        let modal = Modal.getOrCreateInstance(document.getElementById("brochure_download_modal"), {backdrop: 'static', keyboard: false});
        setForm({...Form, product_slug: product_slug})
        if (product_slug.length > 0){
            document.getElementById("brochure_download_link").setAttribute("href", "");
            document.getElementById("brochure_download_button").removeAttribute("disabled");
            modal.show();
        }
        else{
            modal.hide();
        }
    }, [product_slug]);
    const [Form, setForm] = useState({
        name: null,
        email: null,
        phone: null
    })
    const update_form = (e) => {
        setForm({...Form, [e.target.name]: e.target.value});
    }
    const validate = () => {
        if (!Form.name || Form.name.length === 0 || !Form.email || Form.email.length === 0 || !Form.phone || Form.phone.length === 0)
            return false;
        return true;
    }
    const download = (e) => {
        if (!validate()){
            window.alert("All fields are mandatory");
            return false;
        }
        let link = document.getElementById("brochure_download_link");
        e.target.setAttribute("disabled", true);
        downloadBrochure(Form).then(res => {
            if (res.success){
                link.setAttribute("href", res.redirect_url);
                link.click();
                updateBrochureProduct();
            }
            else{
                window.alert("Something went wrong");
            }
        })
        e.target.removeAttribute("disabled");
    }
    return (
        <div className="modal fade" id="brochure_download_modal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">Download Brochure</h5>
                    {/* <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> */}
                    </div>
                    <div className="modal-body">
                    <p>Please fill in the below form to download the brochure.</p>
                    <form id="brochure_download_form">
                        <div className="form-group">
                            <input name="name" className="form-control" onChange={update_form} value={Form.name} placeholder="Name" />
                        </div>
                        <div className="form-group">
                            <PhoneInput name="phone" Form={Form} setForm={setForm} className="mt-3" />
                        </div>
                        <div className="form-group">
                            <input name="email" className="form-control mt-3" onChange={update_form} value={Form.email} placeholder="Email" />
                        </div>
                    </form>
                    </div>
                    <div className="modal-footer">
                    {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button> */}
                    <button type="button" className="btn btn-secondary" onClick={e => updateBrochureProduct()}>Cancel</button>
                    <button type="button" className="btn btn-primary" id='brochure_download_button' onClick={download}>Download</button>
                    <a href="" className="d-none" target="_blank" id="brochure_download_link" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { updateBrochureProduct, downloadBrochure })(BrochureDownloadModal);