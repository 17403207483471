import React from "react";
import { connect } from "react-redux";
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";

const About = ({ update_page_meta }) => {
    update_page_meta({
        title: "About Us | Mobipay",
        description: "About mobipay"
    })
    return (
        <>
            <PageHeader title="About Us" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "About Us"
                }
            ]} bgImg={require('../../assets/images/page-bg/about.webp')} />
            <div className="unified-platform-area bg-color-fff9f8 ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInLeft delay-0-4s">
                                <div className="mb-30">
                                    <span className="top-title">Since 2017</span>
                                    <h2>Mobipay Has Been Helping Clients.</h2>
                                    <p>Promoted by well established serial entrepreneurs who have been pioneers in building organizations focusing on technology and innovation, Mobipay once again brings about creativity and technology innovation to customers bridging gaps in existing services. Developing solutions around retailing and integrating mobile technology as a technology platform, Mobipay is working actively on areas of :</p>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Bridging technology gaps</h3>
                                            <p>Digitising and bridging the technology gap in mobile couponing / redemptions, combining it with innovations on customer reach / loyalty programs and customer engagement.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Disruptive Retailing Services</h3>
                                            <p>Delivering customized Physical retailing front end solutions including advanced and new generation credit / debit cards integrated to POS terminals and including customer loyalty programs and engagement services for enhanced revenues and services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-img three wow fadeInRight delay-0-4s ml-50">
                                <img src={require("../../assets/images/Mobipay_Has_Been_Helping_Clients.webp")} alt="Image" />
                                <img src={require("../../assets/images/unified-shape-1.png")} className="unified-shape-1" alt="Image" />
                                <img src={require("../../assets/images/unified-shape-2.png")} className="unified-shape-2" alt="Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, { update_page_meta })(About);