import React from "react";
import { connect } from "react-redux";

const SecureBanking = ({}) => {
    return (
        <>
            <div className="unified-platform-area pt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/mulah-creative-1.webp")} alt="Mulah" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    <h2>SIM overlay secure banking solution</h2>
                                    <p>
                                        Introducing our SIM overlay secure banking solution, a revolutionary way for consumers to access 
                                        banking services using their feature phones. With our solution, a secure SIM overlay is added to a 
                                        consumer's existing SIM card, allowing them to access a range of banking services such as account 
                                        balance inquiries, money transfers, and bill payments. With UPI integration and Bharat Bill Pay 
                                        integrated, this becomes your one stop serviced for financial inclusion serving the unbanked and
                                        the under-banked.
                                    </p>
                                    <p>
                                        Feature phones are here to stay :
                                    </p>
                                        <ul>
                                            <li>Battery life</li>
                                            <li>Simplicity and</li>
                                            <li>Affordability.</li>
                                        </ul>
                                    
                                    <p>
                                        For the MSME/ traveling salesmen, rural workers…. These phones are their backbone for communication.
                                        Additionally, the SIM overlay provides an extra layer of security, ensuring that customer's sensitive 
                                        information is protected at all times.
                                        Our solution is fully compliant with industry standards and works with a wide range of feature phone 
                                        models, making it easy for consumers to use. It is also cost-effective and easy to implement for 
                                        financial institutions, helping to bring financial services to unbanked & the underbanked.
                                        Choose our SIM overlay secure banking solution and experience the convenience of providing a full 
                                        suite of financial inclusion services to your feature phone customers.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pb-50">
                <div className="container">
                    <div className="main-content-text">
                        <p>
                            Here are some demos :
                        </p>
                        <div className="row">
                            <div className="col-md-6" style={{padding: "15px"}}>
                                <h6>How it works</h6>
                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/BjcrwfHt730" title="Mulah  Secure Banking on the mobile" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className="col-md-6" style={{padding: "15px"}}>
                                <h6>The sample menu UI</h6>
                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/fmGmRgEAqwU" title="Smart Banking on any mobile" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                        {/* <p>
                            <strong style={{color: "#ee6a3e"}}>
                                Building for Bharat and the world… that’s where we are headed and getting the unbanked/under-banked to upgrade to a world of digital payments
                            </strong>
                        </p> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, {})(SecureBanking);