import React from "react";
import { connect } from "react-redux";
import { Route, Routes as BrowserRoutes } from 'react-router-dom';
import Home from "../pages/Home";
import ProductRoutes from "./ProductRoutes";
import About from "../pages/about/About";
import Team from "../pages/about/Team";
import Contact from "../pages/Contact";
import Disclaimer from "../pages/Disclaimer";
import Compliance from "../pages/Compliance";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Story from "../pages/about/Story";
import Bharat from "../pages/about/Bharat";
import Partners from "../pages/about/Partners";
import AnnouncementList from "../pages/announcements/AnnouncementList";

const Routes = ({}) => {
    return (
        <>
            
            <BrowserRoutes>
                <Route path="/" element={<Home/>} />
                <Route path="/about/" element={<About/>} />
                <Route path="/about/story/" element={<Story />} />
                <Route path="/about/building-for-bharat/" element={<Bharat />} />
                <Route path="/about/team/" element={<Team/>} />
                <Route path="/about/our-partners/" element={<Partners/>} />
                <Route path="/contact-us/" element={<Contact/>} />
                <Route path="/disclaimer/" element={<Disclaimer/>} />
                <Route path="/privacy/" element={<PrivacyPolicy/>} />
                <Route path="/compliance/" element={<Compliance/>} />
                <Route path="/announcements/" element={<AnnouncementList/>} />
            </BrowserRoutes>
            <ProductRoutes />
            
        </>
    )
}

export default connect(null, {})(Routes);