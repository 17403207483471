import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../actions/page/header";
import { postEnquiry } from "../actions/support/enquiry";
import PageHeader from "../components/PageHeader";
import PhoneInput from "../components/PhoneInput";
import { address, callto, email, phone } from "../data/constants";
import { getProductsApplications } from "../data/products";

const Products = getProductsApplications();

const Contact = ({ update_page_meta, postEnquiry }) => {
    update_page_meta({
        title: "Contact Us",
        description: "Contact Mobipay"
    })
    const [Form, setForm] = useState({
        name: null,
        designation: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
        products: [],
    })
    const updateForm = (e) => {
        setForm({...Form, [e.target.name]: e.target.value})
    }
    const updateSelectedProducts = (e, slug) => {
        if (e.target.checked) {
            if (!Form.products.includes(slug)) {
                setForm((prevForm) => ({
                    ...prevForm,
                    products: [...prevForm.products, slug],
                }));
            }
        } else {
            setForm((prevForm) => ({
                ...prevForm,
                products: prevForm.products.filter((item) => item !== slug),
            }));
        }
    };
    const validate = () => {
        if (!Form.name || Form.name.length === 0 || !Form.email || Form.email.length === 0 || !Form.phone || Form.phone.length === 0 || !Form.message || Form.message.length === 0)
            return false;
        return true;
    }
    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!validate()){
            // document.getElementById("contactForm").submit();
            window.alert("All fields are required.");
            return;
        }
        postEnquiry(Form).then(res => {
            if (res.success){
                setForm({
                    name: '',
                    designation: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                    products: [],
                })
                window.alert("Thank you for writing to us. We will get in touch soon.")
            }
            else{
                window.alert("Something went wrong. Please try again");
            }
        })
    }
    return (
        <>
            <PageHeader title="Contact Us" breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: "Contact Us"
                }
            ]} bgImg={require("../assets/images/page-bg/Contact_Us.webp")} />
            <section className="contact-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="contacts-info">
                                <h2>Get In Touch</h2>
                                <p>Found any of our <Link to="/category/products/">products</Link> / <Link to="/category/applications/">applications</Link> interesting? Feel free to contact us to know more.</p>

                                <ul className="address">
                                    <li>
                                        <span>Email</span>
                                        <a href={`mailto:${email}`}>{email}</a>
                                    </li>
                                    <li>
                                        <span>Call Us</span>
                                        <a href={callto}>{phone}</a>
                                    </li>
                                    <li className="location">
                                        <span>Our Location</span>
                                        {address}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="contact-form">
                                <h2>Message Us Anytime</h2>

                                <form id="contactForm">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" 
                                                    name="name" 
                                                    id="name"
                                                    className="form-control" 
                                                    required={true} data-error="Please enter your name" 
                                                    placeholder="Name" value={Form.name} onChange={updateForm} />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" 
                                                    name="designation" 
                                                    id="designation"
                                                    className="form-control" 
                                                    required={true} data-error="Please enter your designation" 
                                                    placeholder="Designation" value={Form.designation} onChange={updateForm} />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
            
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" id="email" className="form-control" required={true} data-error="Please enter your email" placeholder="Email" 
                                                    value={Form.email} onChange={updateForm} />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <PhoneInput name="phone" Form={Form} setForm={setForm} className="mb-3" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
            
                                        {/* <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="subject" id="msg_subject" className="form-control" required={true} data-error="Please enter your subject" placeholder="Your Subject" 
                                                    value={Form.subject} onChange={updateForm}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div> */}

                                        <div className="col-lg-12 col-md-12">
                                            <div className="product-list" style={{backgroundColor: "#eee", padding: "10px 20px"}}>
                                                <div className="title">Select products you want to enquire about</div>
                                                {
                                                    Products.map((product, index) => {
                                                        return (
                                                            <label key={index} style={{display: "block"}}><input type="checkbox" name="products" onChange={(e) => updateSelectedProducts(e, product.slug)} checked={Form?.products.find(item => item === product.slug) ? true : false} /> {product.name}</label>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
            
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" id="message" cols="30" rows="6" required={true} data-error="Write your message" placeholder="Your Message Here"
                                                    value={Form.message} onChange={updateForm}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault-1" />
                                                <label className="form-check-label" for="flexCheckDefault-1">
                                                    I accept all <a href="terms-conditions.html">Terms & Conditions</a> 
                                                </label>
                                            </div> */}

                                            <div>
                                            {/* onClick={e => submitForm(e)} */}
                                                <button type="submit" className="default-btn" onClick={e => submitForm(e)}>
                                                    Send Message
                                                </button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="map-area pb-100">
                <div className="container">
                    <div className="map-content">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.667290903271!2d77.31676991515066!3d28.57975188243874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce45a47ca7753%3A0x9cb870f9871ecf79!2sNextGen%20Telesolutions%20Pvt.%20Ltd.%2C!5e0!3m2!1sen!2sin!4v1673607852671!5m2!1sen!2sin"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, { update_page_meta, postEnquiry })(Contact);