import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { address, callto, email, phone } from "../data/constants";

const Footer = ({}) => {
    return (
        <>
            <div className="footer-area pt-100 pb-70 bg-color-0f0e0e">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-widget">
                                <div className="footer-logo">
                                    <Link to="/">
                                        <img src={require("../assets/images/white-logo.png")} alt="Image" />
                                    </Link>
                                </div>
                                <p>Promoted by well established serial entrepreneurs who have been pioneers in building organizations focusing on technology and innovation, Mobipay once again brings about creativity and technology innovation to customers bridging gaps in existing services.</p>

                                <p>CIN: U74999DL2017PTC315482</p>

                                <ul className="social-link">
                                    <li>
                                        <span>Follow Us:</span>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/Nextgen-Telesolutions-Pvt-Ltd-250801648968159" target="_blank">
                                            <i className="flaticon-facebook"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="https://instagram.com/" target="_blank">
                                            <i className="flaticon-instagram"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://twitter.com/Nextgentelein" target="_blank">
                                            <i className="flaticon-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/nextgen-telesolutions-pvt-ltd/" target="_blank">
                                            <i className="flaticon-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-widget ml-30">
                                <h3>Quick Links</h3>
                                
                                <ul className="use-full-link">
                                    <li>
                                        <Link to="/about/">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/category/products/">Products</Link>
                                    </li>
                                    <li>
                                        <Link to="/category/hardware/">Hardware</Link>
                                    </li>
                                    <li>
                                        <Link to="/category/applications/">Applications</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-widget ml-30">
                                <h3>Help Center</h3>
                                
                                <ul className="use-full-link">
                                    <li>
                                        <Link to="/contact-us/">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/disclaimer/">Disclaimer</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy/">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/compliance/">Statutory Compliance</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-widget">
                                <h3>Contact Info</h3>
                                
                                <ul className="address-link">
                                    <li>
                                        <i className="flaticon-phone-call"></i>
                                        <span>Call Us : </span>
                                        <a href={callto}>{phone}</a>
                                    </li>
                                    <li>
                                        <i className="flaticon-location-pin"></i>
                                        <span>Address : </span>
                                        {address}
                                    </li>
                                    <li>
                                        <i className="flaticon-email"></i>
                                        <span>Mail Us : </span>
                                        <a href={`mailto:${email}`}>{email}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-area bg-color-1e1d1d">
                <div className="container">
                    <div className="copy-right-content">
                        <p>© {new Date().getFullYear()} Mobipay. Developed by <a href="https://www.ampliquity.com/" target="blank">Ampliquity Technologies</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(Footer);