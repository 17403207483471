import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { update_page_meta } from "../../actions/page/header";
import CalltoAction from "../../components/CalltoAction";
import PageHeader from "../../components/PageHeader";
import { getProduct } from "../../data/products";

const ProductDetail = ({ update_page_meta }) => {
    const { productSlug } = useParams();
    const [Template, setTemplate] = useState(null);
    const [Product, setProduct] = useState(getProduct(productSlug));
    useEffect(() => {
        setTemplate(Product?.Template);
    }, [Product]);
    update_page_meta({
        title: Product.name,
        description: Product.small_description
    })
    return (
        <>
            <PageHeader title={Product.name} breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: Product.name
                }
            ]} bgImg={Product?.banner} />
            {Template && <Template />}
            {/* <CalltoAction /> */}
        </>
    )
}

export default connect(null, { update_page_meta })(ProductDetail);