import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { update_page_meta } from "../../actions/page/header";
import PageHeader from "../../components/PageHeader";
import { Categories, getCategory } from "../../data/categories";
import { getProducts } from "../../data/products";
import PosTerminals from "./hardware/PosTerminals";
import QRScanners from "./hardware/QRScanners";
import VendingMachines from "./hardware/VendingMachines";
import Modal from 'bootstrap';
import { updateBrochureProduct } from "../../actions/support/brochure";

const ProductList = ({ update_page_meta, updateBrochureProduct }) => {
    const {categorySlug} = useParams();
    const [Category, setCategory] = useState(null);
    const [Products, setProducts] = useState([]);
    useEffect(() => {
        setCategory(getCategory(categorySlug));
        setProducts(getProducts(categorySlug));
    }, [categorySlug]);
    update_page_meta({
        title: Category?.name,
        description: `Products listed in ${Category?.name}`
    })
    const items = Products.map(item => {
        let linkTo = `/shop/${item.slug}/`;
        if (["hardware", "hardware-vending-machine-payment-terminals", "hardware-qr-scanners", "hardware-pos-terminals"].indexOf(categorySlug) !== -1){
            linkTo = `/category/${categorySlug}/`;
        }
        return (
            <div class="col-lg-3 col-sm-6">
                <div class="single-product single-support wow fadeInUp delay-0-2s">
                    {item.thumbnail && <img src={item.thumbnail} alt={item.name} />}
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                        <h3><Link to={linkTo}>{item.name}</Link></h3>
                        <p>{item.small_description}</p>
                        <span className="links">
                            {item.has_detail && <Link to={linkTo} class="read-more me-3">Learn More</Link>}
                            {item.brochure && <Link to="" onClick={e => {e.preventDefault(); updateBrochureProduct(item.slug)}} class="read-more">Download Brochure</Link>}
                        </span>
                    </div>
                </div>
            </div>
        )
    })
    return (
        <>
            <PageHeader title={Category?.name} breadcrumbs={[
                {
                    title: "Home",
                    loc: "/"
                },
                {
                    title: Category?.name
                }
            ]} bgImg={Category?.banner} />
            {Category?.Template && <Category.Template />}
            <div class="support-area bg-color-fbfbfb pt-100 pb-70">
                <div class="container">
                    {/* <div class="section-title">
                        <span class="top-title">Why Bitr Best</span>
                        <h2>A Technology-First Approach</h2>
                        <p>Connect your money to your friends & family from anywhere, anytime regardless any delay. Lorem ipsum Nullana integer sagittis, eleifend. met, aliquere.</p>
                    </div> */}

                    <div class="row wow fadeInUp delay-0-2s">
                        {items}
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, { update_page_meta, updateBrochureProduct })(ProductList);