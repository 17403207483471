export const email = "corporate@mobipaysecuri.com";
export const phone = "+91 98110 67807";
export const callto = `tel:+919811067807`;
export const address = (
  <>
    2nd Floor, B-174, <br />
    B–Block, Shivalik, <br />
    New Delhi – 110 017, INDIA
  </>
);
