import React from "react";
import { connect } from "react-redux";

const Supermax7130NM = ({}) => {
    return (
        <>
            Supermax7130NM
        </>
    )
}

export default connect(null, {})(Supermax7130NM);