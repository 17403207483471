import React, { useEffect } from "react";
import { connect } from "react-redux";
import CSRFToken from "../components/CSRFToken";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/scss/animate.scss";
import 'swiper/css';
import 'swiper/css/bundle';
import "../assets/scss/style.scss";
import "../assets/scss/responsive.scss";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import WOW from 'wowjs';
import { usePageMeta } from "../reducers/pagemetaReducer";
import Footer from "../components/Footer";
import BrochureDownloadModal from "./BrochureDownloadModal";

const Layout = ({children}) => {
    const pageMeta = usePageMeta();
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [pageMeta])
    return (
        <>
            <CSRFToken />
            {/* {<Preloader />} */}
            <Navbar />
            {children}
            <Footer />

            <BrochureDownloadModal />
        </>
    )
}

export default connect(null, {})(Layout);