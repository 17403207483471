import React, { useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store";
import Layout from "./hocs/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Head from "./hocs/Head";
import Routes from "./routes/Routes";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Head />
        <Layout>
          <ScrollToTop />
          <Routes />
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
