import React from "react";
import { connect } from "react-redux";

const EVoucher = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/products/products/E-voucher_E-coupon_Solutions.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our SMS-based e-voucher solution for mobile services, a convenient and secure way 
                                        for consumers to purchase and redeem vouchers/ tokens / cash backs and other transactional 
                                        services. Consumers can purchase e-vouchers via SMS or be sent an E-voucher over an SMS text - 
                                        good for any customer mobile device, and integrated with the merchant SMS text scanning SDK, 
                                        it’s an all inclusive solution for retailers, governments and merchants.
                                    </p>
                                    <p>
                                    With an extra layer of security using an advanced encryption and tokenization to protect sensitive 
                                    information, our hosted solution gives you an instant support platform for enhancing customer 
                                    services engagement and value addition.
                                    Our SMS-based e-voucher solution is fully compliant with industry standards and regulations, 
                                    and it is also cost-effective and easy to implement for mobile service providers, making it 
                                    accessible for all kinds of customers.
                                    </p>
                                    
                                    <p>
                                        Choose our SMS-based e-voucher solution for mobile services and experience the convenience 
                                        of purchasing and redeeming mobile services via SMS.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(EVoucher);