import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const CalltoAction = ({}) => {
    return (
        <>
            <div class="started-area bg-color-ee6a3e">
                <div class="container">
                    <div class="started-bg">
                        <div class="started-content">
                            <h3>Ready To Get Started?</h3>
                            <p>Want to explore Mobipay more? You can always contact 
                                us to design a custom payment package for your business.</p>

                            <div class="started-btn">
                                {/* <a href="register.html" class="default-btn">
                                    Start Now
                                </a> */}
                                <Link to="/contact-us/" class="default-btn active">
                                    Contact Us Now
                                </Link>
                            </div>
                        </div>

                        <img src={require("../assets/images/started-shape-1.png")} class="shape shape-1" alt="Image" />
                        <img src={require("../assets/images/started-shape-2.png")} class="shape shape-2" alt="Image" />
                        <img src={require("../assets/images/started-shape-3.png")} class="shape shape-3" alt="Image" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(CalltoAction);