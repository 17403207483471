export const partners = {
    technological: [
        {
            name: "Fly Wallet",
            thumbnail: require("../assets/images/partners/technological/flywallet.webp")
        },
        {
            name: "IDFC Bank",
            thumbnail: require("../assets/images/partners/technological/IDFC_Bank_Logo.webp")
        },
        {
            name: "Federal Bank",
            thumbnail: require("../assets/images/partners/technological/Federal_Bank.webp")
        },
        {
            name: "Soft Space",
            thumbnail: require("../assets/images/partners/technological/softspace_logo.webp")
        },
        {
            name: "IDEMIA",
            thumbnail: require("../assets/images/partners/technological/idemia.webp")
        },
    ],
    strategic: [
        {
            name: "Sa-Dhan",
            thumbnail: require("../assets/images/partners/strategic/Sa-Dhan.webp")
        },
        {
            name: "KaiOS",
            thumbnail: require("../assets/images/partners/strategic/kaios.webp")
        },
        {
            name: "MeitY",
            thumbnail: require("../assets/images/partners/strategic/meity.webp")
        },
        {
            name: "Vi",
            thumbnail: require("../assets/images/partners/strategic/Vodaidea.webp")
        },
        {
            name: "Chilitag",
            thumbnail: require("../assets/images/partners/strategic/chilitag.webp")
        },
    ],
    customers: [
        {
            name: "IIFL Samasta",
            thumbnail: require("../assets/images/partners/customers/iifl-samasta.webp")
        },
        {
            name: "Midland Microfin",
            thumbnail: require("../assets/images/partners/customers/midland-microfin.webp")
        },
        {
            name: "PayNearby",
            thumbnail: require("../assets/images/partners/customers/PayNearby.webp")
        },
        {
            name: "Satin Creditcare Network",
            thumbnail: require("../assets/images/partners/customers/satin.webp")
        },
        {
            name: "Muthoot Finance",
            thumbnail: require("../assets/images/partners/customers/muthoot-finance.webp")
        },
        {
            name: "Airtel Payments Bank",
            thumbnail: require("../assets/images/partners/customers/Airtel_payments_bank_logo.webp")
        },
        {
            name: "Grameen Shakti Microfinance",
            thumbnail: require("../assets/images/partners/customers/grameen_shakti.webp")
        },
        {
            name: "mBnk",
            thumbnail: require("../assets/images/partners/customers/mBnk.webp")
        },
        {
            name: "PayPoint",
            thumbnail: require("../assets/images/partners/customers/paypoint.webp")
        },
        {
            name: "Tafani",
            thumbnail: require("../assets/images/partners/customers/tafani.webp")
        },
        {
            name: "Sahaj",
            thumbnail: require("../assets/images/partners/customers/sahaj.webp")
        },
        {
            name: "Tata Capital",
            thumbnail: require("../assets/images/partners/customers/tata-capital.webp")
        },
        {
            name: "Razorpay",
            thumbnail: require("../assets/images/partners/customers/Razorpay.webp")
        },
        {
            name: "Spice Money",
            thumbnail: require("../assets/images/partners/customers/spice-money.webp")
        },
    ]
}