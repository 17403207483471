import axios from "axios";
import { JSON_CONTENT_FORM_CONFIG } from "../Config";

export const getAnnouncements = async (page=1, perPage=6) => {
    let param = `page=${page}`;
    if (perPage)
        param += `&perPage=${perPage}`;
    try{
        let response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/updates/list/?${param}`, JSON_CONTENT_FORM_CONFIG);
        return response.data
    }
    catch{
        return {
            success: false,
            message: "Something went wrong. Please try again"
        }
    }
}