import React from "react";
import { connect } from "react-redux";

const PosTerminals = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/POS_Terminals.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing the POS terminal of the future! Our cutting-edge technology allows for fast, 
                                        secure and contactless transactions using the latest NFC mobile devices, QR code "pull" 
                                        payments directly from a customer's mobile wallet and EMV card payments through our SoftPOS 
                                        application. Whether you're a small business or a large enterprise, our POS terminal will 
                                        revolutionize the way you process transactions and offer your customers a seamless and 
                                        convenient experience. Upgrade to the future of payments today!
                                    </p>
                                    <p>
                                        The low cost solution with a downloadable merchant app that integrates with the payment 
                                        terminal at the merchant end can easily be deployed across your network. Quick, low cost 
                                        and covering the whole ecosystem of payments for the future.
                                    </p>
                                    <p>
                                        View our devices for your Aadhaar services – can be sold as an OTC product with remote 
                                        integration with your merchant app and put to immediate use.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(PosTerminals);