import React from "react";
import { connect } from "react-redux";

const SR50 = ({}) => {
    return (
        <>
            SR50
        </>
    )
}

export default connect(null, {})(SR50);