import React from "react";
import { connect } from "react-redux";

const MFIApplication = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/Financial_Inclusion_Solutions_for_the_Micro_Finance_Industry.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our SIM overlay feature phone banking solution, designed to empower micro 
                                        finance industry and handle UPI AutoPay and EMI collect services. With our solution, 
                                        feature phone users can easily access financial services like bill payments, money transfers, 
                                        and merchant payments, using multiple UPI Virtual Payment Addresses (VPA), bank accounts. 
                                        Built with the latest technology, and it seamlessly integrates with your existing systems, 
                                        providing a secure, convenient and efficient way to manage and handle financial transactions 
                                        on feature phones. 
                                    </p>
                                    
                                    <p>
                                        Upgrade your microfinance operations today with our innovative SIM overlay 
                                        feature phone banking solution.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(MFIApplication);