import React from "react";
import { connect } from "react-redux";
import { update_page_meta } from "../actions/page/header";
import Banner from "../components/home/Banner";
import ContentSection1 from "../components/home/ContentSection1";
import ContentSection2 from "../components/home/ContentSection2";
import ContentSection3 from "../components/home/ContentSection3";
import HowSupport from "../components/home/HowSupport";
import WhyMobipay from "../components/home/WhyMobipay";

const Home = ({ update_page_meta }) => {
    update_page_meta({
        title: "Home Mobipay",
        description: "Mobipay description"
    })
    return (
        <>
            <Banner />
            {/* <WhyMobipay /> */}
            {/* <ContentSection1 /> */}
            <ContentSection2 />
            {/* <ContentSection3 /> */}
            {/* <HowSupport /> */}
        </>
    )
}

export default connect(null, { update_page_meta })(Home);