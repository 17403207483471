import PosTerminals from "../pages/products/hardware/PosTerminals";
import QRScanners from "../pages/products/hardware/QRScanners";
import VendingMachines from "../pages/products/hardware/VendingMachines";

export const Categories = [
    {
        name: "Products",
        slug: "products",
        Template: null,
        banner:require("../assets/images/page-bg/Products_Banner.webp"),
        sub_categories: []
    },
    {
        name: "Applications",
        slug: "applications",
        Template: null,
        banner: require("../assets/images/page-bg/Applications.webp"),
        sub_categories: []
    },
    {
        name: "Hardware",
        slug: "hardware",
        Template: null,
        banner: require("../assets/images/page-bg/hardware.webp"),
        sub_categories: [
            {
                name: "Bus / Transit Validator / Vending Machine Payment Terminals",
                slug: "hardware-vending-machine-payment-terminals",
                Template: VendingMachines,
                banner: require("../assets/images/page-bg/Bus_Transit_Valid_tor_Vending_Machine_Payment_Terminals_Banner.webp"),
                sub_categories: []
            },
            {
                name: "QR Scanners",
                slug: "hardware-qr-scanners",
                Template: QRScanners,
                banner: require("../assets/images/page-bg/QR_Scanners_Banner.webp"),
                sub_categories: []
            },
            {
                name: "POS Terminals",
                slug: "hardware-pos-terminals",
                Template: PosTerminals,
                banner: require("../assets/images/page-bg/POS_Terminals_Banner.webp"),
                sub_categories: []
            },

        ]
    }
]



export const getCategory = (slug) => {
    let item = null;
    const finditem = (n) => {
        let x = null;
        if (n.slug === slug)
            return n;
        for (var j=0; j<n.sub_categories.length; j++){
            x = finditem(n.sub_categories[j]);
            if (x)
                break;
        }
        return x;
    }
    for (var i=0; i<Categories.length; i++){
        item = finditem(Categories[i]);
        if (item)
            break;
    }
    return item;
}
