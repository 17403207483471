import React from "react";
import { connect } from "react-redux";

const AutomaticParkingPayment = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/products/products/Automatic_Parking_Payment_Solutions.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our Automatic Parking Ticketing Solution, a cutting-edge technology designed to 
                                        streamline the process of parking ticketing. Our solution utilizes contactless technology 
                                        such as RFID or NFC, allowing drivers to simply tap their contactless payment card or mobile 
                                        device to pay for their parking without the need for cash or physical tickets.
                                    </p>
                                    <p>
                                        Our solution provides an easy, fast and convenient way for drivers to pay for their parking 
                                        and eliminates the need for manual ticketing, reducing the chances of human errors and 
                                        increasing efficiency. It also offers a range of options for fare payment, including 
                                        credit/debit cards, mobile wallets, and parking cards, and it can also integrate with existing 
                                        parking systems.
                                    </p>
                                    <p>
                                        Our Automatic Parking Ticketing Solution is also designed to be cost-effective and easy to 
                                        implement for parking operators, helping to modernize their parking ticketing systems and 
                                        improve the overall parking experience for drivers.
                                    </p>
                                    <p>
                                        Choose our Automatic Parking Ticketing Solution and experience the convenience and efficiency 
                                        of contactless parking ticketing.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(AutomaticParkingPayment);