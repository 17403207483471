import React from "react";
import { Route, Routes } from 'react-router-dom';
import ProductDetail from "../pages/products/ProductDetail";
import ProductList from "../pages/products/ProductList";

const ProductRoutes = () => {
    return (
        <Routes>
            {/* <Route path="/products/" element={<ProductList/>} />
            <Route path="/hardware/" element={<ProductList/>} />
            <Route path="/solutions/" element={<ProductList/>} /> */}
            <Route path="/category/:categorySlug/" element={<ProductList/>} />
            <Route path="/shop/:productSlug/" element={<ProductDetail/>} />
        </Routes>
    )
}

export default ProductRoutes;