import React from "react";
import { connect } from "react-redux";

const VendingMachines = ({}) => {
    return (
        <>
            <div className="unified-platform-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                                <img src={require("../../../assets/images/Bus_Transit_Valid_tor_Vending_Machine_Payment_Terminals.webp")} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="unified-platform-content wow fadeInRight delay-0-4s">
                                <div className="mb-30">
                                    {/* <span className="top-title">Unified Platform</span> */}
                                    {/* <h2>SIM overlay secure banking solution</h2> */}
                                    <p>
                                        Introducing our all-in-one payment terminal solution, designed to revolutionize the way you 
                                        manage vending machines. Our terminals are equipped with the latest technology, including 
                                        support for the MDB protocol, allowing for seamless integration with your vending machines. 
                                        Our terminals also offer advanced vending machine management services, allowing you to easily 
                                        monitor and control your machines remotely. Plus, with the ability to display advertising on 
                                        the terminals, you can generate additional revenue while providing a convenient service to your 
                                        customers.
                                    </p>
                                    <p>
                                        Upgrade your vending operations today with our cutting-edge payment terminal solution.
                                    </p>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, {})(VendingMachines);